import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Tooltip,
  IconButton,
  Link,
  Button,
} from "@material-ui/core";
import TableHeadCell from "./TableHeadCell";
import Alert from "@material-ui/lab/Alert";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import axios from "axios";
import CustomerList from "./CustomerList";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory,useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CustomerSims = (props) => {
  const history = useHistory();
  const { user } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [orders, setOrders] = useState([]);
  const [sims, setSims] = useState([]);
  const [error, setError] = useState(null);
  let query = useQuery();
  console.log();
  useEffect(() => {
    setLoading(true);
    axios
      .get("GetCustomers")
      .then(({ data }) => {
        setCustomers(data.data);
        setLoading(false);
        for (let customer of data.data){
          if(query.get("hash") === customer.hash){
            setSelectedCustomer(customer);
            break;
          }
        }
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
      // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
     
      window.history.replaceState(null,selectedCustomer.name, `/customer-sims?hash=${selectedCustomer.hash}`)

      setLoading(true);
      axios
        .post("GetOrderSummary", { customer_id: selectedCustomer.id })
        .then(({ data }) => {
          console.log(data);
          setOrders(data.orders);
          setSims(data.sims);
          // setCustomers(data.data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, [selectedCustomer]);
  return (
    <React.Fragment>
      {loading && <Loader />}
      <Container fixed>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box mt={2}>
              <CustomerList
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
                customers={customers}
              />
            </Box>
          </Grid>

          {sims.length === 0 &&
            orders.length === 0 &&
            selectedCustomer &&
            loading === false && (
              <Grid item>
                <Box pt={3}>
                  <Alert severity="info"> No Orders Found. </Alert>
                </Box>
              </Grid>
            )}

          {error && (
            <Box pt={3} pb={3}>
              <Alert severity="error">
                <p dangerouslySetInnerHTML={{ __html: error }}></p>{" "}
              </Alert>
            </Box>
          )}

          {orders.length !== 0 && (
            <React.Fragment>
              <Grid item>
                <Typography variant="h5" align="left">
                  Orders History
                </Typography>
              </Grid>

              <Grid item>
                <TableContainer component={Paper}>
                  <Table size="small" style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell>Order Num</TableHeadCell>
                        <TableHeadCell>SIMs Ordered</TableHeadCell>
                        <TableHeadCell>Plans Activated</TableHeadCell>
                        <TableHeadCell>Devices Ordered</TableHeadCell>
                        <TableHeadCell>Action</TableHeadCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {orders &&
                        orders.map((order) => {
                          return (
                            <TableRow key={order.order_num}>
                              <TableCell>
                                {order.order_num}
                                <Link
                                  rel="noopener"
                                  href={`${
                                    user[window.location.origin + "/API_URL"]
                                  }/invoice/download/${
                                    user[window.location.origin + "/COMPANY_ID"]
                                  }?order_hash=${order.order_hash}`}
                                >
                                  <Tooltip title="Download PDF">
                                    <IconButton size="small">
                                      <CloudDownloadIcon color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                              </TableCell>
                              <TableCell>{order.sim_count}</TableCell>
                              <TableCell>{order.plan_count}</TableCell>
                              <TableCell>{order.device_count}</TableCell>
                              <TableCell>
                                {order.plan_count !== 0 && (
                                  <Grid container spacing={2}>
                                  <Grid item>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={()=>{
                                      history.push(`/customer/${selectedCustomer.hash}/order/${order.order_num}/activate`);
                                    }}
                                  >
                                    Activate
                                  </Button>
                                  </Grid>

                                 <Grid item>
                                 <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={()=>{
                                      history.push(`/customer/${selectedCustomer.hash}/order/${order.order_num}/status`);
                                    }}
                                  >
                                    Status
                                  </Button>
                                 </Grid>
                                  </Grid>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </React.Fragment>
          )}

          {sims.length !== 0 && (
            <React.Fragment>
              <Grid item>
                <Box mt={2}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    spacing={2}
                  >
                    <Grid item>
                      <Typography variant="h5" align="left">
                        SIMs
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Copy SIMs">
                        <IconButton
                          onClick={() => {
                            let copyText = sims
                              .map((sim) => {
                                return `${sim.iccid}`;
                              })
                              .join("\r\n");
                            navigator.clipboard.writeText(copyText);
                            // setShowConnectionStatusDeviceId(item.deviceNO);
                          }}
                          size="small"
                        >
                          <FileCopyIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item>
                <TableContainer component={Paper}>
                  <Table size="small" style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell>ICCID</TableHeadCell>
                        <TableHeadCell>SIM Type</TableHeadCell>
                        <TableHeadCell>Activated Plan</TableHeadCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {sims &&
                        sims.map((sim) => {
                          return (
                            <TableRow key={sim.iccid}>
                              <TableCell>{sim.iccid}</TableCell>
                              <TableCell>{sim.sim_type}</TableCell>
                              <TableCell>{sim.plan}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default CustomerSims;
