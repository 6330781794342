import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
  logo: {
    flexGrow: "1",
  },
  reportBtn: {
    textTransform: "none",
  },
}));
const Navigation = () => {
  const { isAuthenticated, loginWithRedirect, logout,user } = useAuth0();
  if(user!== undefined){
    axios.defaults.headers.common['Authorization'] = user[window.location.origin+'/API_KEY'];
    axios.defaults.headers.common['X-API-URL'] = user[window.location.origin+'/API_URL'];
  }
  

  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <Box className={classes.logo} pb={2}>
              <Typography variant="h5" color="primary">
              IoT Billing Platform
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Grid container direction="row"  spacing={3}>
              {isAuthenticated && (
                <React.Fragment>
                 
                  <Box mr={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.reportBtn}
                      startIcon={<AddIcon />}
                      component={Link}
                      to="/new-order"
                    >
                      New Order
                    </Button>
                  </Box>

                  <Box mr={1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.reportBtn}
                      startIcon={<PersonAddIcon />}
                      component={Link}
                      to="/create-customer"
                    >
                      New Customer
                    </Button>
                  </Box>
                  <Box mr={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.reportBtn}
                      startIcon={<ListIcon />}
                      component={Link}
                      to="/customer-sims"
                    >
                      Customer SIMs
                    </Button>
                  </Box>

                  <Box mr={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.reportBtn}
                      startIcon={<HighlightOffIcon />}
                      component={Link}
                      to="/close-lines"
                    >
                      Close Lines
                    </Button>
                  </Box>

                </React.Fragment>
              )}

              <Box mr={1}>
                {!isAuthenticated ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.reportBtn}
                    startIcon={<PermIdentityIcon />}
                    onClick={
                      () => loginWithRedirect()
                      // {redirectUri:`${window.location.origin}/devices`}
                    }
                  >
                    Login
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.reportBtn}
                    startIcon={<ExitToAppIcon />}
                    onClick={() =>
                      logout({
                        returnTo: window.location.origin,
                      })
                    }
                  >
                    Logout
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
