import React from "react";
import "./App.css";
// import Header from "./components/Header";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles"; 
import { grey } from "@material-ui/core/colors";
import NewOrder from "./components/NewOrder";
import Profile from "./components/profile";
import CustomerSims from './components/CustomerSims';
import Navigation from "./components/Navigation";
import Loader from "./components/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from './components/protected-route';
import CreateCustomer from './components/CreateCustomer';
import CancelLines from './components/CancelLines';
import ActivateSim from "./components/ActivateSim";
import SimActivationStatus from "./components/SimActivationStatus";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1D4354",
    },
    secondary: {
      main: "#6FDA44",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  colors: {
    green: "#6FDA44",
    darkBlue: "#1D4354",
    grey: "#f2f2f2",
  },
});

function App() {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <Navigation />

          <div className="mainPanel" style={{ backgroundColor: grey[50] }}>
         
          <ProtectedRoute path="/" exact={true} component={NewOrder} />
            <ProtectedRoute path="/new-order" exact component={NewOrder} />
            <ProtectedRoute path="/create-customer" exact component={CreateCustomer} />
            <ProtectedRoute path="/customer-sims" exact component={CustomerSims}/>
            <ProtectedRoute path="/close-lines" exact component={CancelLines} />
            <ProtectedRoute path="/profile" exact component={Profile} />
            <ProtectedRoute path="/customer/:customer_hash/order/:order_num/activate" exact component={ActivateSim} />
            <ProtectedRoute path="/customer/:customer_hash/order/:order_num/status" exact component={SimActivationStatus} />
          
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
