import React, { useState, useEffect } from "react";
import "../styles/customer-journey.css";
import Loader from "./Loader";
import axios from "axios";
import {
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  DialogContent,
  DialogTitle,
  Dialog,
  TableHead,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import SendIcon from "@material-ui/icons/Send";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TableHeadCell from "../components/TableHeadCell";
import CustomerList from "./CustomerList";

const OrderType = Object.freeze({
  SIM_PURCHASE: "sim_purchase",
  PLAN_ACTIVATION: "plan_activation",
  DEVICE: "device",
  SIM_PURCHASE_AND_ACTIVATE: "sim_purchase_and_activate"
});

const NewOrder = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [simTypes, setSimTypes] = useState([]);
  const [devices, setDevices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const [selectedSimType, setSelectedSimType] = useState(null);

  const [plans, setPlans] = useState([]);

  const [selectedPlan, setSelectedPlan] = useState(null);

  const [noOfDevices, setNoOfDevices] = useState(0);

  const [orderType, setOrderType] = useState(OrderType.SIM_PURCHASE);

  const [sims, setSims] = useState("");
  const [successMessage, setSuccessMessage] = useState();
  const [previewData, setPreviewData] = useState(undefined);
  const [previewData1, setPreviewData1] = useState(false);
  const [previewData2, setPreviewData2] = useState(false);
  const [newErrorMesg, setNewErrorMesg] = useState("");



  useEffect(() => {
    setLoading(true);
    Promise.all([
      axios.get("GetCustomers"),
      axios.get("GetDevices"),
      axios.get("GetPlans"),
    ])
      .then(
        ([{ data: customers_data }, { data: devices }, { data: plans }]) => {
          setCustomers(customers_data.data);
          setSimTypes(devices.sims);
          setDevices(devices.devices);
          setPlans(plans);
          setLoading(false);
        }
      )
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const getOrderData = (i,m) => {
    if (selectedCustomer === null) {
      alert("Select Customer");
      return;
    }

    if (orderType === OrderType.SIM_PURCHASE && selectedSimType === null) {
      alert("Please Select Sim Type");
      return;
    }

    if (orderType === OrderType.PLAN_ACTIVATION && selectedPlan === null) {
      alert("Please Select Plan");
      return;
    }

    if (orderType === OrderType.SIM_PURCHASE_AND_ACTIVATE && selectedPlan === null) {
      alert("Please Select Plan");
      return;
    }

    if (orderType === OrderType.SIM_PURCHASE_AND_ACTIVATE || m===2) {

      if(i===0){
        let order_data = {
          customer_id: selectedCustomer.id,
          order_type: 'sim_purchase',
          sim_id: selectedSimType,
          plan_id: null,
          sims: sims.trim() === "" ? [] : sims.trim().split("\n"),
        };
    
        return order_data;
  
      }else{

        let order_data = {
          customer_id: selectedCustomer.id,
          order_type: 'plan_activation',
          sim_id: selectedSimType,
          plan_id: selectedPlan,
          sims: sims.trim() === "" ? [] : sims.trim().split("\n"),
        };
    
        return order_data;



      }
      
    }

    if (orderType === OrderType.DEVICE) {
      if (noOfDevices === 0) {
        alert("Enter Number Of Devices");
        return;
      }

      console.log(selectedDevice);

      let order_data = {
        customer_id: selectedCustomer.id,
        order_type: orderType,
        no_of_devices: parseInt(noOfDevices),
        device_id: selectedDevice,
      };
      console.log(order_data);
      return order_data;
    }

    let order_data = {
      customer_id: selectedCustomer.id,
      order_type: orderType,
      sim_id: selectedSimType,
      plan_id: selectedPlan,
      sims: sims.trim() === "" ? [] : sims.trim().split("\n"),
    };

    return order_data;
  };

  const previewOrder = () => {
    let order_data = getOrderData();
    if (order_data === undefined) {
      return;
    }
    if (orderType === OrderType.SIM_PURCHASE_AND_ACTIVATE) {

       order_data = {
        customer_id: selectedCustomer.id,
        order_type: 'sim_purchase_and_activate',
        sim_id: selectedSimType,
        plan_id: selectedPlan,
        sims: sims.trim() === "" ? [] : sims.trim().split("\n"),
      };

    }
    console.log(order_data);
    setLoading(true);
    axios
      .post("CreateOrder", { ...order_data, preview: true })
      .then(({ data }) => {
        console.log(data);
        if (data.status === "error") {
          let errorMessages = [];
          for (const item in data.data) {
            console.log(item);
            console.log(data.data[item][0]);
            errorMessages.push(data.data[item][0]);
           
          }
          console.log(errorMessages.join("\r\n"));
          setError(errorMessages.join("</br>"));
          setSuccessMessage(undefined);
        } else if (data.status === "success") {
          setError(undefined);
          
          let previewData = data.data;
          let items = [];
          for (const itemType in data.data.summary) {
            console.log(itemType);
            for (const item in data.data.summary[itemType]) {
              const itemObj = data.data.summary[itemType][item];

              items.push({
                name: itemObj[itemType.substring(0, itemType.length - 1)],
                price: itemObj["prices"].reduce(function (a, b) {
                  return a + b;
                }, 0),
                quantity: itemObj["quantity"],
              });
            }
            console.log(items);
            previewData.items = items;
            setPreviewData(previewData);
            // errorMessages.push(data.data[item][0]);
          }
          console.log(data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data.error);
        setLoading(false);
      });
  };

  const placeOrder = async (m) => {
   
    if(orderType === OrderType.SIM_PURCHASE_AND_ACTIVATE || m===2){
      console.log(m);
      for (var i = 0; i < 2; i++) {

        let order_data = getOrderData(i,m);
      if (order_data === undefined) {
        return;
      }
      console.log(order_data);
      setLoading(true);
       await axios
        .post("CreateOrder", order_data)
        .then(({ data }) => {
          console.log(data);
          if (data.status === "error") {
            let errorMessages = [];
            for (const item in data.data) {
              console.log(item);
              console.log(data.data[item][0]);
              errorMessages.push(data.data[item][0]);
            }
            console.log(errorMessages.join("\r\n"));
            setError(errorMessages.join("</br>"));
            setSuccessMessage(undefined);
            setLoading(false);
            return;
          } else if (data.status === "success") {
            setError(undefined);
            setSuccessMessage(data.data);
          }else if(data.status === "error2"){

            let errorMessages = [];
            console.log(data.data);
            for (const item in data.data) {
              console.log(item);
              console.log(data.data[item]);
              errorMessages.push('The Sim Number '+ item +' is assigned to '+data.data[item]['customer']['fname']+' '+data.data[item]['customer']['lname']+' ('+data.data[item]['customer']['company_name']+') and the status is '+data.data[item]['status']);
              
              console.log(orderType);
              if(orderType === OrderType.SIM_PURCHASE){
                setPreviewData2(true)
              }else{

                setPreviewData1(true)
              }
              
            }

            console.log(errorMessages.join("\r\n"));
            
            setNewErrorMesg(errorMessages.join("</br>"))
            setSuccessMessage(undefined);
            setLoading(false);

          }
          
          setLoading(false);
        })
        .catch((error) => {
          setError(error.response.data.error);
          setLoading(false);
          return;
        });
        
      } 

    }else{


      let order_data = getOrderData();
      if (order_data === undefined) {
        return;
      }
      console.log(order_data);
      setLoading(true);
      axios
        .post("CreateOrder", order_data)
        .then(({ data }) => {
          console.log(data);
          if (data.status === "error") {
            let errorMessages = [];
            
            for (const item in data.data) {
              console.log(item);
              console.log(data.data[item][0]);
              errorMessages.push(data.data[item][0]);
              
             
            }
            console.log(errorMessages.join("\r\n"));
            setError(errorMessages.join("</br>"));
            setSuccessMessage(undefined);
            setLoading(false);
            return;
          } else if (data.status === "success") {
            setError(undefined);
            setSuccessMessage(data.data);
            setLoading(false);
          } else if(data.status === "error2"){

            let errorMessages = [];
            console.log(data.data);
            for (const item in data.data) {
              console.log(item);
              console.log(data.data[item]);
              errorMessages.push('The Sim Number '+ item +' is assigned to '+data.data[item]['customer']['fname']+' '+data.data[item]['customer']['lname']+' ('+data.data[item]['customer']['company_name']+') and the status is '+data.data[item]['status']);
              
              console.log(orderType);
              if(orderType === OrderType.SIM_PURCHASE){
                setPreviewData2(true)
              }else{

                setPreviewData1(true)
              }
            }

            console.log(errorMessages.join("\r\n"));
            
            setNewErrorMesg(errorMessages.join("</br>"))
            setSuccessMessage(undefined);
            setLoading(false);

          }
          setLoading(false);
        })
        .catch((error) => {
          setError(error.response.data.error);
          setLoading(false);
          return;
        });


    }

    
  };

  const onclose= () => {

    setPreviewData1(undefined);


  };
  const onclose2= () => {

    setPreviewData2(undefined);

  };
  const btnClicked2 = async () =>{

    setPreviewData2(undefined)
    setLoading(true);
    let orders = [];
    let sims_extracted = sims.trim() === "" ? [] : sims.trim().split("\n");
    for (const sim of sims_extracted) {
      orders.push({ sim_num: sim });
    }

    let order_data = {
      customer_id: selectedCustomer.id,
      'close_subscription': "1",
      orders,
    };
    await axios
      .post("CloseLines", order_data)
      .then(({ data }) => {
        console.log(data);
        if (data.status === "error") {
          let errorMessages = [];
          for (const item in data.data) {
            console.log(item);
            console.log(data.data[item][0]);
            errorMessages.push(data.data[item][0]);
          }
          console.log(errorMessages.join("\r\n"));
          setError(errorMessages.join("</br>"));
          setSuccessMessage(undefined);
          setLoading(false);
          return;
        } else if (data.status === "success") {
          setError(undefined);
          setSuccessMessage(data.data);
        }
       
      })
      .catch((error) => {
        setError(error.response.data.error);
        setLoading(false);
          return;
        
      });

      order_data = {
        customer_id: selectedCustomer.id,
        'unassign_sim': "1",
        orders,
      };
//       setTimeout(function(){
//  	alert("Sup!"); 
// }, 2000);
      await axios
      .post("CloseLines", order_data)
      .then(({ data }) => {
        console.log(data);
        if (data.status === "error") {
          let errorMessages = [];
          for (const item in data.data) {
            console.log(item);
            console.log(data.data[item][0]);
            errorMessages.push(data.data[item][0]);
          }
          console.log(errorMessages.join("\r\n"));
          setError(errorMessages.join("</br>"));
          setSuccessMessage(undefined);
          setLoading(false);
          return;
        } else if (data.status === "success") {
          setError(undefined);
          setSuccessMessage(data.data);
          
        }
        
      })
      .catch((error) => {
        setError(error.response.data.error);
        setLoading(false);
        return;
      });

      order_data = {
        customer_id: selectedCustomer.id,
        order_type: 'sim_purchase',
        sim_id: selectedSimType,
        plan_id: null,
        sims: sims.trim() === "" ? [] : sims.trim().split("\n"),
      };
      console.log(order_data);
      setLoading(true);
       await axios
        .post("CreateOrder", order_data)
        .then(({ data }) => {
          console.log(data);
          if (data.status === "error") {
            let errorMessages = [];
            for (const item in data.data) {
              console.log(item);
              console.log(data.data[item][0]);
              errorMessages.push(data.data[item][0]);
            }
            console.log(errorMessages.join("\r\n"));
            setError(errorMessages.join("</br>"));
            setSuccessMessage(undefined);
            setLoading(false);
            return;
          } else if (data.status === "success") {
            setError(undefined);
            setSuccessMessage(data.data);
          }else if(data.status === "error2"){

            let errorMessages = [];
            console.log(data.data);
            for (const item in data.data) {
              console.log(item);
              console.log(data.data[item]);
              errorMessages.push('The Sim Number '+ item +' is assigned to '+data.data[item]['customer']['fname']+' '+data.data[item]['customer']['lname']+' ('+data.data[item]['customer']['company_name']+') and the status is '+data.data[item]['status']);
              
              if(orderType === OrderType.SIM_PURCHASE){
                setPreviewData2(true)
              }else{

                setPreviewData1(true)
              }
              
            }

            console.log(errorMessages.join("\r\n"));
            
            setNewErrorMesg(errorMessages.join("</br>"))
            setSuccessMessage(undefined);
            setLoading(false);

          }
          
          setLoading(false);
        })
        .catch((error) => {
          setError(error.response.data.error);
          setLoading(false);
          return;
        });


       
        
}
     


  
  const btnClicked = async () =>{

    setPreviewData1(undefined)
    setLoading(true);
    let orders = [];
    let sims_extracted = sims.trim() === "" ? [] : sims.trim().split("\n");
    for (const sim of sims_extracted) {
      orders.push({ sim_num: sim });
    }

    let order_data = {
      customer_id: selectedCustomer.id,
      'close_subscription': "1",
      orders,
    };
    await axios
      .post("CloseLines", order_data)
      .then(({ data }) => {
        console.log(data);
        if (data.status === "error") {
          let errorMessages = [];
          for (const item in data.data) {
            console.log(item);
            console.log(data.data[item][0]);
            errorMessages.push(data.data[item][0]);
          }
          console.log(errorMessages.join("\r\n"));
          setError(errorMessages.join("</br>"));
          setSuccessMessage(undefined);
          setLoading(false);
          return;
        } else if (data.status === "success") {
          setError(undefined);
          setSuccessMessage(data.data);
        }
       
      })
      .catch((error) => {
        setError(error.response.data.error);
        setLoading(false);
          return;
        
      });

      order_data = {
        customer_id: selectedCustomer.id,
        'unassign_sim': "1",
        orders,
      };
//       setTimeout(function(){
//  	alert("Sup!"); 
// }, 2000);
      await axios
      .post("CloseLines", order_data)
      .then(({ data }) => {
        console.log(data);
        if (data.status === "error") {
          let errorMessages = [];
          for (const item in data.data) {
            console.log(item);
            console.log(data.data[item][0]);
            errorMessages.push(data.data[item][0]);
          }
          console.log(errorMessages.join("\r\n"));
          setError(errorMessages.join("</br>"));
          setSuccessMessage(undefined);
          setLoading(false);
          return;
        } else if (data.status === "success") {
          setError(undefined);
          setSuccessMessage(data.data);
          
        }
        
      })
      .catch((error) => {
        setError(error.response.data.error);
        setLoading(false);
        return;
      });
     

      placeOrder(2);
      
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Container fixed>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box mt={2}>
              <Typography variant="h5" align="left">
                {props.title}
              </Typography>
            </Box>
          </Grid>

          {error && (
            <Box pt={3} pb={3}>
              <Alert severity="error">
                <p dangerouslySetInnerHTML={{ __html: error }}></p>{" "}
              </Alert>
            </Box>
          )}

          {successMessage && (
            <Box pt={3} pb={3}>
              <Alert severity="success"> {successMessage} </Alert>
            </Box>
          )}
          <Grid item>
            <Grid container direction="row" justify="space-between">
              <Grid item xs={12} lg={8}>
                <CustomerList
                  selectedCustomer={selectedCustomer}
                  setSelectedCustomer={setSelectedCustomer}
                  customers={customers}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <Grid container direction="row" spacing={2} justify="flex-end">
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={previewOrder}
                      startIcon={<VisibilityIcon />}
                    >
                      Preview Order
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={placeOrder}
                      startIcon={<SendIcon />}
                    >
                      Place Order
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <FormLabel component="legend">Order Type</FormLabel>
              <RadioGroup
                row
                value={orderType}
                aria-label="gender"
                name="customized-radios"
                onChange={(event) => {
                  setOrderType(event.target.value);
                }}
              >
                <FormControlLabel
                  value={OrderType.SIM_PURCHASE}
                  control={<Radio />}
                  label="SIM Purchase"
                />
                <FormControlLabel
                  value={OrderType.PLAN_ACTIVATION}
                  control={<Radio />}
                  label="Activate Plan"
                />
                <FormControlLabel
                  value={OrderType.SIM_PURCHASE_AND_ACTIVATE}
                  control={<Radio />}
                  label="SIM Purchase & Activate Plan"
                />
                <FormControlLabel
                  value={OrderType.DEVICE}
                  control={<Radio />}
                  label="Purchase Device"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <FormControl fullWidth>
                  <InputLabel id="action-select-label">
                    Select SIM Type
                  </InputLabel>
                  <Select
                    labelId="select-sim-type"
                    id="sim-type"
                    value={selectedSimType}
                    onChange={(e) => setSelectedSimType(e.target.value)}
                    disabled={orderType === OrderType.DEVICE}
                  >
                    {simTypes.map((sim, index) => (
                      <MenuItem value={sim.id} key={index}>
                        {sim.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={4}>
                <FormControl fullWidth>
                  <InputLabel id="select-plan-label">Select Plan</InputLabel>
                  <Select
                    labelId="select-plan"
                    id="plan"
                    value={selectedPlan}
                    onChange={(e) => setSelectedPlan(e.target.value)}
                    disabled={orderType === OrderType.DEVICE}
                  >
                    {plans.map((plan, index) => (
                      <MenuItem value={plan.id} key={index}>
                        {plan.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={4}>
                <Grid container justify="flex-start" spacing={1}>
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel id="select-device-label">
                        Select Device
                      </InputLabel>
                      <Select
                        labelId="select-device"
                        id="device"
                        value={selectedDevice}
                        onChange={(e) => setSelectedDevice(e.target.value)}
                        disabled={orderType !== OrderType.DEVICE}
                      >
                        {devices.map((device, index) => (
                          <MenuItem value={device.id} key={index}>
                            {device.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <TextField
                      id="noOfDevices"
                      label="No Of Devices"
                      type="number"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      value={noOfDevices}
                      disabled={orderType !== OrderType.DEVICE}
                      onChange={(e) => setNoOfDevices(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <TextField
                  id="outlined-multiline-static"
                  label="SIM Number (ICCID)"
                  multiline
                  rows={10}
                  variant="outlined"
                  fullWidth
                  value={sims}
                  onChange={(e) => {
                    setSims(e.target.value);
                  }}
                  disabled={orderType === OrderType.DEVICE}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          onClose={() => {
            setPreviewData(undefined);
          }}
          open={previewData}
          aria-labelledby="simple-dialog-title"
          fullWidth={false}
          maxWidth={"lg"}
        >
          <DialogTitle id="simple-dialog-title">Preview Order Data</DialogTitle>
          <DialogContent>
            {previewData && (
              <React.Fragment>
                {/* {previewData.msg && (
                  <Box pt={3}>
                    <Alert severity="error"> {deviceData.msg} </Alert>
                  </Box>
                )} */}
                {previewData && (
                  <React.Fragment>
                    <Box pb={3}>
                      <TableContainer component={Paper}>
                        <Table size="small" style={{ tableLayout: "fixed" }}>
                          <TableHead>
                            <TableRow>
                              <TableHeadCell>Item</TableHeadCell>
                              <TableHeadCell>Qty</TableHeadCell>
                              <TableHeadCell>Price</TableHeadCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {previewData.items &&
                              previewData.items.map((item) => {
                                return (
                                  <TableRow>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.quantity}</TableCell>
                                    <TableCell>{item.price} &nbsp; $</TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>

                    <TableContainer component={Paper}>
                      <Table size="small" style={{ tableLayout: "fixed" }}>
                        <TableRow>
                          <TableCell>Activation Fees</TableCell>
                          <TableCell>
                            {previewData.activationFees} &nbsp; $
                          </TableCell>
                        </TableRow>
                        <TableBody>
                          <TableRow>
                            <TableCell>Monthly Charge</TableCell>
                            <TableCell>
                              {previewData.monthlyCharge} &nbsp; $
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Regulatory </TableCell>
                            <TableCell>
                              {previewData.regulatory} &nbsp; $
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Taxes </TableCell>
                            <TableCell>{previewData.taxes} &nbsp; $</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Sub Total Price </TableCell>
                            <TableCell>
                              {previewData.subtotalPrice} &nbsp; $
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Total Price </TableCell>
                            <TableCell>
                              {previewData.totalPrice} &nbsp; $
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </DialogContent>
        </Dialog>
        <Dialog
          onClose={() => {
            setPreviewData1(undefined);
          }}
          open={previewData1}
          aria-labelledby="simple-dialog-title"
          fullWidth={false}
          maxWidth={"lg"}
        >
          <DialogTitle id="simple-dialog-title">Order Data</DialogTitle>
          <DialogContent>
           
            <p dangerouslySetInnerHTML={{ __html: newErrorMesg }}></p>{" "}
            <Button
           variant="contained"
           color="primary"
           startIcon={<SendIcon />}
             onClick={btnClicked}
            >UNASSIGN AND ACTIVATE</Button>

            <Button
           style={{ marginLeft: '0.8rem' }}
           variant="contained"
           color="primary"
           
             onClick={onclose}
            >CLOSE</Button>
          </DialogContent>
        </Dialog>

        <Dialog
          onClose={() => {
            setPreviewData2(undefined);
          }}
          open={previewData2}
          aria-labelledby="simple-dialog-title"
          fullWidth={false}
          maxWidth={"lg"}
        >
          <DialogTitle id="simple-dialog-title">Order Data</DialogTitle>
          <DialogContent>
           
            <p dangerouslySetInnerHTML={{ __html: newErrorMesg }}></p>{" "}
            <Button
           variant="contained"
           color="primary"
           startIcon={<SendIcon />}
             onClick={btnClicked2}
            >UNASSIGN AND REASSIGN</Button>

            <Button
           style={{ marginLeft: '0.8rem' }}
           variant="contained"
           color="primary"
           
             onClick={onclose2}
            >CLOSE</Button>
          </DialogContent>
        </Dialog>
      </Container>
    </React.Fragment>
  );
};

NewOrder.defaultProps = {
  title: "New Order",
};

export default NewOrder;
