import React, { useState,useEffect } from "react";
import "../styles/customer-journey.css";
import Loader from "./Loader";
import axios from "axios";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  Radio,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import SendIcon from "@material-ui/icons/Send";
import CustomerList from './CustomerList';

const OrderType = Object.freeze({
  CLOSE_SUBSCRIPTION: "close_subscription",
  UNASSIGN_SIM: "unassign_sim",
});

const CancelLines = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [orderType, setOrderType] = useState(OrderType.UNASSIGN_SIM);
  const [sims, setSims] = useState("");
  const [successMessage, setSuccessMessage] = useState();
  const [customers, setCustomers] = useState([]);
  useEffect(() => {
    setLoading(true);
    axios
      .get("GetCustomers")
      .then(({ data }) => {
        setCustomers(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);
  const getOrderData = () => {
    if (selectedCustomer === null) {
      alert("Select Customer");
      return;
    }
    let sims_extracted = sims.trim() === "" ? [] : sims.trim().split("\n");
    let orders = [];
    for (const sim of sims_extracted) {
      orders.push({ sim_num: sim });
    }
    let order_data = {
      customer_id: selectedCustomer.id,
      [orderType]: "1",
      orders,
    };
    return order_data;
  };

  const cancelLines = () => {
    let order_data = getOrderData();
    if (order_data === undefined) {
      return;
    }
    console.log(order_data);
    setLoading(true);
    axios
      .post("CloseLines", order_data)
      .then(({ data }) => {
        console.log(data);
        if (data.status === "error") {
          let errorMessages = [];
          for (const item in data.data) {
            console.log(item);
            console.log(data.data[item][0]);
            errorMessages.push(data.data[item][0]);
          }
          console.log(errorMessages.join("\r\n"));
          setError(errorMessages.join("</br>"));
          setSuccessMessage(undefined);
        } else if (data.status === "success") {
          setError(undefined);
          setSuccessMessage(data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.error);
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Container fixed>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box mt={2}>
              <Typography variant="h5" align="left">
                {props.title}
              </Typography>
            </Box>
          </Grid>

          {error && (
            <Box pt={3} pb={3}>
              <Alert severity="error">
                <p dangerouslySetInnerHTML={{ __html: error }}></p>{" "}
              </Alert>
            </Box>
          )}

          {successMessage && (
            <Box pt={3} pb={3}>
              <Alert severity="success"> {successMessage} </Alert>
            </Box>
          )}
          <Grid item>
            <Grid container direction="row" justify="space-between">
              <Grid item xs={12} lg={8}>
              <CustomerList selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} customers={customers}/>
              </Grid>

              <Grid item xs={12} lg={4}>
                <Grid container direction="row" spacing={2} justify="flex-end">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={cancelLines}
                      startIcon={<SendIcon />}
                    >
                      Cancel Lines
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <FormLabel component="legend">Order Type</FormLabel>
              <RadioGroup
                row
                value={orderType}
                aria-label="gender"
                name="customized-radios"
                onChange={(event) => {
                  setOrderType(event.target.value);
                }}
              >
                <FormControlLabel
                  value={OrderType.UNASSIGN_SIM}
                  control={<Radio />}
                  label="Unassign SIM"
                />
                <FormControlLabel
                  value={OrderType.CLOSE_SUBSCRIPTION}
                  control={<Radio />}
                  label="Close Subscription"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <TextField
                  id="outlined-multiline-static"
                  label="SIM Number (ICCID)"
                  multiline
                  rows={10}
                  variant="outlined"
                  fullWidth
                  value={sims}
                  onChange={(e) => {
                    setSims(e.target.value);
                  }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

CancelLines.defaultProps = {
  title: "Cancel Lines",
};

export default CancelLines;
