import React from "react";

import { TextField, FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
const CustomerList = (props) => {

 
  return (
    <FormControl fullWidth>
          <Autocomplete
        value={props.selectedCustomer}
        id="combo-box-demo"
        options={props.customers}
        getOptionLabel={(option) =>
          `${option.name}` +
          (option.company !== null && option.company !== undefined
            ? ` (${option.company})`
            : "")
        }
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField {...params} label="Select Customer" variant="outlined" />
        )}
        onChange={(event, newValue) => {
          props.setSelectedCustomer(newValue);
        }}
      />
    </FormControl>
  );
};

export default CustomerList;
