import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
  Button
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import States from "./states";
import CustomerList from './CustomerList';


const CreateCustomer = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [password, setPassword] = useState("");
  const [shipping_fname, setShippingFName] = useState("");
  const [shipping_lname, setShippingLName] = useState("");
  const [shipping_address1, setShippingAddress1] = useState("");
  const [shipping_address2, setShippingAddress2] = useState("");
  const [shipping_city, setShippingCity] = useState("");
  const [shipping_state_id, setShippingStateId] = useState();
  const [shipping_zip, setShippingZip] = useState("");
  const [phone, setPhone] = useState("");
  const [pin, setPin] = useState("");
  const [billing_state_id, setBillingStateId] = useState();
  const [billing_fname, setBillingFName] = useState("");
  const [billing_lname, setBillingLName] = useState("");
  const [billing_address1, setBillingAddress1] = useState("");
  const [billing_address2, setBillingAddress2] = useState("");
  const [billing_city, setBillingCity] = useState("");
  const [billing_zip, setBillingZip] = useState("");
  const [surcharge , setSurcharge] = useState(0);
  const [csvInvoiceEnabled  , setCSVInvoiceEnabled] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get("GetCustomers")
      .then(({ data }) => {
        setCustomers(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    if (selectedCustomer) {
      setLoading(true);
      axios
        .get("GetCustomer", { params: { hash: selectedCustomer.hash } })
        .then(({ data }) => {
          console.log(data);
          setFName(data.fname);
          setLName(data.lname);
          setPin(data.pin);
          setEmail(data.email);
          setPhone(data.phone);
          setCompany(data.company_name===null ? '' : data.company_name);
          setShippingAddress1(data.shipping_address1);
          setShippingAddress2(data.shipping_address2);
          setShippingCity(data.shipping_city);
          setShippingFName(data.shipping_fname);
          setShippingLName(data.shipping_lname);
          setShippingStateId(
            States.filter(
              (state) => state.abbreviation === data.shipping_state_id
            )[0]
          );
          setShippingZip(data.shipping_zip);

          setBillingFName(data.billing_fname);
          setBillingLName(data.billing_lname);
          setBillingAddress1(data.billing_address1);
          setBillingAddress2(data.billing_address2);
          setBillingCity(data.billing_city);
          setBillingStateId(
            States.filter(
              (state) => state.abbreviation === data.billing_state_id
            )[0]
          );
          setBillingZip(data.billing_zip);

          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, [selectedCustomer]);

  const createCustomer = () => {
    let customer_data = {
      email,
      password,
      fname,
      lname,
      shipping_fname,
      shipping_lname,
      shipping_address1,
      shipping_address2,
      shipping_city,
      shipping_state_id: shipping_state_id
        ? shipping_state_id.abbreviation
        : shipping_state_id,
      shipping_zip,
      phone,
      pin,
      billing_state_id: billing_state_id
        ? billing_state_id.abbreviation
        : billing_state_id,
      billing_fname,
      billing_lname,
      billing_address1,
      billing_address2,
      billing_city,
      billing_zip,
      company_name:company,
      surcharge:parseInt(surcharge),
      csv_invoice_enabled : csvInvoiceEnabled ? 1 : 0
    };
    console.log(customer_data);
    setLoading(true);
    axios
    .post("CreateCustomer",customer_data)
    .then(({ data }) => {
      console.log(data);
      if(data.status === 'error'){
        let errorMessages = [];
        for (const item in data.data) {
          console.log(item);
          console.log(data.data[item][0]);
          errorMessages.push(data.data[item][0]);
        }
        console.log(errorMessages.join('\r\n'));
        setError(errorMessages.join('</br>'));
        setSuccessMessage(undefined);
      }else if(data.status=== 'success'){
        setError(undefined);
        setSuccessMessage('Customer Created Successfully.');
      }
      // setCustomers(data.data);
      setLoading(false);
    })
    .catch((error) => {

      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      {loading && <Loader />}

      <Container fixed>
        <Box mt={2}>
          <Grid
            container
            direction="row"
            spacing={2}
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5" align="left">
                    {props.title} or Clone From &nbsp;
                  </Typography>
                </Grid>
                <Grid item>
                <CustomerList selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} customers={customers}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={createCustomer}
              >
                Create Customer
              </Button>
            </Grid>
          </Grid>
        </Box>
        {error && (
          <Box pt={3}>
            <Alert severity="error">
              <p dangerouslySetInnerHTML={{ __html: error }}></p>{" "}
            </Alert>
          </Box>
        )}

        {successMessage && (
          <Box pt={3}>
            <Alert severity="success"> {successMessage} </Alert>
          </Box>
        )}
        <Grid container justify="center">
          <Grid item xs={12}>
            <Paper elevation={3}>
              <Box mt={3} p={1}>
                <Grid container direction="column" justify="center" spacing={2}>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={12} lg={3}>
                        <TextField
                          id="email"
                          label="Email"
                          type="text"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <TextField
                          id="password"
                          label="Password"
                          type="password"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <TextField
                          id="pin"
                          label="Pin"
                          type="number"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={pin}
                          onChange={(e) => setPin(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <TextField
                          id="company"
                          label="Company"
                          type="text"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                        />
                      </Grid>

                      
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={12} lg={4}>
                        <TextField
                          id="firstName"
                          label="First Name"
                          type="text"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={fname}
                          onChange={(e) => setFName(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <TextField
                          id="lastName"
                          label="Last Name"
                          type="text"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={lname}
                          onChange={(e) => setLName(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <TextField
                          id="phoneNumber"
                          label="Phone Number"
                          type="text"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <TextField
                          id="surcharge"
                          label="Surcharge"
                          type="number"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          value={surcharge}
                          onChange={(e) => setSurcharge(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                      <FormControlLabel
            control={<Checkbox checked={csvInvoiceEnabled} onChange={(e)=>{setCSVInvoiceEnabled(e.target.checked)}} name="CSV Invoice" />}
            label="CSV Invoice"
          />
                      </Grid>

                   
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Typography variant="h6">Shipping Details</Typography>
                  </Grid>
                  <Address
                    firstName={shipping_fname}
                    setFirstName={setShippingFName}
                    lastName={shipping_lname}
                    setLastName={setShippingLName}
                    address1={shipping_address1}
                    setAddress1={setShippingAddress1}
                    address2={shipping_address2}
                    setAddress2={setShippingAddress2}
                    city={shipping_city}
                    setCity={setShippingCity}
                    stateId={shipping_state_id}
                    setStateId={setShippingStateId}
                    zipCode={shipping_zip}
                    setZipCode={setShippingZip}
                    primaryFirstName={fname}
                    primaryLastName={lname}
                  />
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      spacing={2}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography variant="h6">Billing Details</Typography>
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setBillingFName(shipping_fname);
                                  setBillingLName(shipping_lname);
                                  setBillingAddress1(shipping_address1);
                                  setBillingAddress2(shipping_address2);
                                  setBillingCity(shipping_city);
                                  setBillingStateId(shipping_state_id);
                                  setBillingZip(shipping_zip);
                                }
                              }}
                            />
                          }
                          label="Same as Shipping Address"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Address
                    firstName={billing_fname}
                    setFirstName={setBillingFName}
                    lastName={billing_lname}
                    setLastName={setBillingLName}
                    address1={billing_address1}
                    setAddress1={setBillingAddress1}
                    address2={billing_address2}
                    setAddress2={setBillingAddress2}
                    city={billing_city}
                    setCity={setBillingCity}
                    stateId={billing_state_id}
                    setStateId={setBillingStateId}
                    zipCode={billing_zip}
                    setZipCode={setBillingZip}
                    primaryFirstName={fname}
                    primaryLastName={lname}
                  />
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

CreateCustomer.defaultProps = {
  title: "Create Customer",
};

export default CreateCustomer;

const Address = (props) => {


  const [selectedState, setSelectedState] = useState({});
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    address1,
    setAddress1,
    address2,
    setAddress2,
    city,
    setCity,
    stateId,
    setStateId,
    zipCode,
    setZipCode,
  } = props;

  useEffect(() => {
    if (stateId) {
      console.log(stateId);
      setSelectedState(stateId);
    }
  }, [stateId]);
  return (
    <React.Fragment>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} lg={4}>
            <TextField
              id="firstName"
              label="First Name"
              type="text"
              variant="outlined"
              fullWidth
              margin="dense"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              id="lastName"
              label="Last Name"
              type="text"
              variant="outlined"
              fullWidth
              margin="dense"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFirstName(props.primaryFirstName);
                      setLastName(props.primaryLastName);
                    }
                  }}
                  name="isNewSequence"
                />
              }
              label="Same as Primary First and Last Name"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} lg={4}>
            <TextField
              id="address1"
              label="Address 1"
              type="text"
              variant="outlined"
              fullWidth
              margin="dense"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              id="address2"
              label="Address 2"
              type="text"
              variant="outlined"
              fullWidth
              margin="dense"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              id="city"
              label="City"
              type="text"
              variant="outlined"
              fullWidth
              margin="dense"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} lg={4}>
            <Autocomplete
              id="locationsList"
              value={selectedState}
              options={States}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                console.log(newValue);
                setStateId(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="State Id"
                  placeholder="Locations"
                  margin="dense"
                />
              )}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <TextField
              id="zipcode"
              label="Zipcode"
              type="text"
              variant="outlined"
              fullWidth
              margin="dense"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
