import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import {
  Button,
  Grid,
  Box,
  Typography,
  Container
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import Loader from "./Loader";
import AddIcon from "@material-ui/icons/Add";
import Alert from "@material-ui/lab/Alert";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import { CSVLink } from "react-csv";

import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import { CSVReader } from "react-papaparse";
import { useHistory } from "react-router-dom";

const ActivateSim = (props) => {
  const history = useHistory();
  const { customer_hash, order_num } = useParams();
  const [lines, setLines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [accountsUploadData, setAccountsUploadData] = useState([]);
  const exportTemplateLink = useRef();
  const columns = [
    // { field: "id", headerName: "ID", width: 100 },
    { field: "sim_card_num", headerName: "Sim Card Num", width: 250 },

    { field: "phone_number", headerName: "Phone Number", width: 250 },
  ];
  const [error, setError] = useState(null);
  useEffect(() => {
    setLoading(true);
    axios
      .get("GetCustomerOrderLines", {
        params: { hash: customer_hash, order_num },
      })
      .then(({ data }) => {
        console.log(data);
        setLines(data);
        // setSelectionModel([1176, 1177])
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [customer_hash, order_num]);

  const activateSelectedAccounts = () => {
    console.log(selectionModel);
  
    let iccids = [];
    for (let line of lines) {
      if(selectionModel.includes(line.id)){
        iccids.push({iccid:line.sim_card_num,subscription_id:line.id});
    }
   
  };
  activateSims(iccids);
  }
  const activateSims = (iccids) =>{
     setLoading(true);
     axios
     .post(`https://falcon-api.britewireless.com/api/v1/iot/activate-sims`, {
       order_no:order_num,
       data:iccids
     })
     .then((response) => {
       alert(response.data.message);
       setLoading(false);
       history.push(`/customer/${customer_hash}/order/${order_num}/status`);
     });
  }
  const activateAllAccounts = () =>{
    console.log(lines);
    let iccids = [];
    for (let line of lines) {
      if(line.phone_number == null){
        iccids.push({iccid:line.sim_card_num,subscription_id:line.id});
      }
    }
   
    activateSims(iccids);
  }
  const handleOnDrop = (records) => {
    let accounts = [];
    for (let record of records) {
      accounts.push({ ...record.data });
    }
    setAccountsUploadData(accounts);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const upload = () => {
    let data = [];
    for (let accountToUpload of accountsUploadData) {
      data.push({ ...accountToUpload });
    }
    setLoading(true);
    console.log(data);
   
  };

  const export_headers = [
    { label: "sim_num", key: "sim_num" },
    { label: "area_code", key: "area_code" },
    { label: "zip_code", key: "zip_code" }
  ];

  return (
    <React.Fragment>
      <Container maxWidth="xl"> 
      {loading && <Loader />}

      <Box pt={2}>
        {order_num && (
          <Typography variant="h4">
            Activate SIM For Order Number {order_num}
          </Typography>
        )}
      </Box>
      {error && (
        <Box pt={3} pb={3}>
          <Alert severity="error">
            <p dangerouslySetInnerHTML={{ __html: error }}></p>{" "}
          </Alert>
        </Box>
      )}
      <Box pt={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              spacing={4}
            >
              <Grid item>
                <CSVReader
                  onDrop={handleOnDrop}
                  onError={handleOnError}
                  addRemoveButton
                  onRemoveFile={handleOnRemoveFile}
                  config={{ header: true, skipEmptyLines: true }}
                  style={{
                    dropArea: {
                      padding: "15px",
                    },
                    removeButton: {
                      color: "red",
                    },
                  }}
                >
                  <span>
                    Drop Account Upload CSV file here or click to upload.
                  </span>
                </CSVReader>
              </Grid>
              
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<PublishIcon />}
                  onClick={upload}
                  disabled={accountsUploadData.length === 0}
                >
                  Upload & Activate
                </Button>
              </Grid>
            </Grid>{" "}
          </Grid>
          <Grid item>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={() => {
                exportTemplateLink.current.link.click();
              }}
            >
              Bulk Upload Template
            </Button>
            <CSVLink
              data={[]}
              headers={export_headers}
              filename={`sim_activate_upload.csv`}
              ref={exportTemplateLink}
            />
          </Grid>
        </Grid>
      </Grid>
        </Grid>
      </Box>
      <Box pt={2}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={activateSelectedAccounts}
              disabled={selectionModel.length === 0}
            >
              Activate Selected SIMs
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<SelectAllIcon />}
              onClick={activateAllAccounts}
            >
              Acitvate all SIMs
            </Button>
          </Grid>
       
        </Grid>
      </Box>
      <Box pt={2}>
        <DataGrid
          rows={lines}
          columns={columns}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) =>
            setSelectionModel(newSelectionModel)
          }
          disableSelectionOnClick
          selectionModel={selectionModel}
          autoHeight
          isRowSelectable={(params) => params.row.phone_number== null}
        />

      </Box>
      </Container>
    </React.Fragment>
  );
};

export default ActivateSim;
