import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import {
  Grid,
  Box,
  Typography,
  Container,
  Tooltip,
  IconButton,
  Button
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import Loader from "./Loader";
import Alert from "@material-ui/lab/Alert";
import RefreshIcon from "@material-ui/icons/Refresh";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";

const SimActivationStatus = (props) => {
  const { customer_hash, order_num } = useParams();
  const [lines, setLines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const exportDataLink = useRef();

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "iccid", headerName: "Sim Card Num", width: 250 },
    { field: "status", headerName: "Status", width: 250 },
    { field: "msisdn", headerName: "MSISDN", width: 150 },
    { field: "error", headerName: "Error", width: 250 },
    { field: "created_at", headerName: "Created At", width: 250 },
    { field: "last_updated_at", headerName: "Last Updated At", width: 250 },
  ];

  const export_headers = [
    { label: "Sim Card Num", key: "iccid" },
    { label: "MSISDN", key: "msisdn" },
    { label: "Status", key: "status" },
    { label: "Created At", key: "created_at" },
    { label: "Completed At", key: "last_updated_at" },
   
  ];
  const [error, setError] = useState(null);
  const refreshData = ()=>{
    setLoading(true);
    axios
      .get(`https://falcon-api.britewireless.com/api/v1/iot/${order_num}/sims`)
      .then(({ data }) => {
        console.log(data);
        setLines(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }

  const requqesims = ()=>{
    setLoading(true);
    axios
      .get(`https://falcon-api.britewireless.com/api/v1/iot/${order_num}/re-queue-sims`)
      .then(({ data }) => {
        console.log(data);
        alert(data.message);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }
  

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line
  }, [customer_hash, order_num]);

  
  
  return (
    <React.Fragment>
      <Container maxWidth="xl"> 
      {loading && <Loader />}

      <Box pt={2}>
        {order_num && (
          <Typography variant="h4">
            SIM Activation Status For Order Number {order_num} 
            <Tooltip title="Refresh Data">
                <IconButton
                  onClick={() => {
                    refreshData();
                  }}
                  size="medium"
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
          </Typography>
        )}
      </Box>
      {error && (
        <Box pt={3} pb={3}>
          <Alert severity="error">
            <p dangerouslySetInnerHTML={{ __html: error }}></p>{" "}
          </Alert>
        </Box>
      )}
    
      <Box pt={2}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
          <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    onClick={() => {
                      exportDataLink.current.link.click();
                    }}
                  >
                    Export
                  </Button>
                  <CSVLink
                    data={lines}
                    headers={export_headers}
                    filename={
                      lines &&
                      `Order_${order_num}_${moment().format(
                        "YYYYMMDD_hmmss"
                      )}.csv`
                    }
                    ref={exportDataLink}
                  />
          </Grid>
          <Grid item>
          <Button
                    variant="outlined"
                    color="primary"
                  
                    onClick={requqesims}
                  >
                    Re-Queue Sims
                  </Button>
          </Grid>
         
       
        </Grid>
      </Box>
      <Box pt={2}>
        <DataGrid
          rows={lines}
          columns={columns}      
          onSelectionModelChange={(newSelectionModel) =>
            setSelectionModel(newSelectionModel)
          }
          disableSelectionOnClick
          selectionModel={selectionModel}
          autoHeight
        />
      </Box>
      </Container>
    </React.Fragment>
  );
};

export default SimActivationStatus;
